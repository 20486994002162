import React from 'react'
import { connect } from 'react-redux';
import {Redirect} from 'react-router-dom';

class Checkauth extends React.Component {
    render() {

        let auth = false;

        if(this.props.adminAuthReducer.auth) 
            auth = this.props.adminAuthReducer.auth;
        else if(localStorage.getItem('adminToken'))
            auth = true
        else    
            auth = false
        
        if(!auth) {
            return <Redirect to="/admin/login" />
        }
        else    
            return null;
        
        // if(!this.props.adminAuthReducer.auth) {
        //     return <Redirect to="/admin/login" />
        // }
        // else    
        //     return null;
    }
    
}

const mapStateToProps = (state) => {
    return {
        adminAuthReducer: state.adminAuthReducer
    }
}

export default connect(mapStateToProps)(Checkauth)