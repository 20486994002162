import React, {Component} from 'react';
import axios from 'axios';

import logo from "assets/img/splash-logo.png";

// @material-ui/icons
// import AddAlert from "@material-ui/icons/AddAlert";
import ErrorOutline from "@material-ui/icons/Error";
import DoneAll from "@material-ui/icons/DoneAll"
//core components
import SnackbarContent from "../components/Snackbar/SnackbarContent.jsx";

import * as PROJ_CONST from "../config/constants";
// import { runInThisContext } from 'vm';

import {connect} from 'react-redux';
import {adminAuthPostAct} from '../rdx/actions/adminAuthActions';

class Login extends Component {
    state = {
        usernameInputClass: 'input100',
        passwordInputClass: 'input100',
        passwordInputType: 'password',
        showPasswordBtnClass: 'zmdi zmdi-eye',
        showPassword: false,
        username: '',
        password: '',
        validationMsg: "",
        open: false
    }

    inputFocus = (e) => {
        if(e.target.name === "username") {
            if(e.target.value !== "") {
                this.setState({
                    usernameInputClass: 'input100 has-val'
                })
            }
            else {
                this.setState({
                    usernameInputClass: 'input100'
                })
            }
        }
        if(e.target.name === "pass") {
            if(e.target.value !== "") {
                this.setState({
                    passwordInputClass: 'input100 has-val'
                })
            }
            else {
                this.setState({
                    passwordInputClass: 'input100'
                })
            }
        }
        
    }

    toggleShowPasswordBtn = () => {
        
        if(!this.state.showPassword) { 
            this.setState({
                showPassword:true,
                showPasswordBtnClass: 'zmdi zmdi-eye-off',
                passwordInputType: 'text'
            })
        }
        else {
            this.setState({
                showPassword:false,
                showPasswordBtnClass: 'zmdi zmdi-eye',
                passwordInputType: 'password'
            })
        }
    }

    showPassword = e => {
        this.toggleShowPasswordBtn();
    }

    formSubmit = e => {
        e.preventDefault();

        const Uname = this.state.username;
        const Pword = this.state.password;

        const data = {
            "username": Uname,
            "password": Pword
        }

        if(Uname === "" || Pword === "") {
            this.setState({
                validationMsg: "Please enter Username/Password.",
                open: true
            })
        }
        else {
            this.setState({
                validationMsg: ""
            })
            this.props.adminAuthPostActProp(data);
        }

        
    }

    getUsername = (e) => {
        
        this.setState({
            username: e.target.value
        })
        
    }

    getPassword = (e) => {
        
        this.setState({
            password: e.target.value
        })
        
    }

    updateValidToken = () => {
        const validToken = this.props.adminAuthReducer.token;
        const username = this.props.adminAuthReducer.admin_username;

        axios
            .get(
                PROJ_CONST.API_ADMIN_VALID_TOKEN+"/"+username,
                {
                    headers: {
                        "x-auth-token": validToken
                    }
                }
            )
            .then((res) => {
                return true;
            })
            .catch(err => {
                return err;
            })
    }
    
    render() {
        if(this.props.adminAuthReducer.auth) {
            
            if(!this.props.adminAuthReducer.tokenUpdate)
                this.updateValidToken();

            setTimeout(()=> {
                this.props.history.push('/admin/dashboard')
            }, 1000);
        }
        return(
            <div>
                {
                    (this.props.adminAuthReducer.loginSuccessMsg !== "") 
                    ? <SnackbarContent message={this.props.adminAuthReducer.loginSuccessMsg}  color="success" icon={DoneAll}/> 
                    : ""
                }
                
                {
                    (this.props.adminAuthReducer.validationMsg !== "") 
                    ? <SnackbarContent message={this.props.adminAuthReducer.validationMsg}  color="danger" icon={ErrorOutline}/> 
                    : ""
                }

                {
                    // Checking and alerting locally inside component using local states
                    (this.state.validationMsg !== "") 
                    ? <SnackbarContent message={this.state.validationMsg}  color="danger" icon={ErrorOutline}/> 
                    : ""
                }
                
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100">
                            <form className="login100-form validate-form" onSubmit={this.formSubmit}>
                                <span className="login100-form-title p-b-26">
                                    Welcome
                                </span>
                                <span className="login100-form-title p-b-48">
                                    <img src={logo} alt="Digi V Cards Logo" style={{width: '200px', height: 'auto'}} />
                                </span>

                                

                                <div className="wrap-input100 validate-input">
                                    <input 
                                        className={this.state.usernameInputClass} 
                                        type="text" 
                                        name="username" 
                                        onFocus={this.inputFocus} 
                                        onBlur={this.inputFocus}
                                        onChange={this.getUsername}
                                        value={this.state.username}
                                    />
                                    <span className="focus-input100" data-placeholder="Username"></span>
                                </div>

                                <div className="wrap-input100 validate-input" data-validate="Enter password">
                                    <span className="btn-show-pass">
                                        <i className={this.state.showPasswordBtnClass} onClick={this.showPassword}></i>
                                    </span>
                                    <input 
                                        className={this.state.passwordInputClass} 
                                        type={this.state.passwordInputType} 
                                        name="pass" 
                                        onFocus={this.inputFocus} 
                                        onBlur={this.inputFocus}
                                        onChange={this.getPassword}
                                    />
                                    <span className="focus-input100" data-placeholder="Password"></span>
                                </div>

                                <div className="container-login100-form-btn">
                                    <div className="wrap-login100-form-btn">
                                        <div className="login100-form-bgbtn"></div>
                                        <button 
                                            className="login100-form-btn"
                                            onClick={this.formSubmit}
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>

                                <div className="text-center p-t-115">
                                    <span className="txt1">
                                        &copy; {PROJ_CONST.PROJECT_NAME}
                                    </span>

                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
	

	            <div id="dropDownSelect1"></div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminAuthPostActProp: (loginCredentials) => {
            dispatch(adminAuthPostAct(loginCredentials))

        },
    }
}

const mapStateToProps = (state) => {
    return {
        adminAuthReducer: state.adminAuthReducer
    }
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
