
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {adminLogoutAct} from '../../../rdx/actions/adminAuthActions'

class Logoutlable extends Component {

    clickLogout = () => {
        
        this.props.adminLogoutAct();
    }

    render() {
        if(!this.props.adminAuthReducerState.auth)
            return <Redirect to="/admin/login" />

        return (
            <span style={{"width":'100%'}} onClick={this.clickLogout} >Logout</span>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminLogoutAct : () => {
            dispatch(adminLogoutAct())
        }
    }
}

const mapStateToProps = (state) => {
    return {
        adminAuthReducerState: state.adminAuthReducer
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logoutlable);