

export const ADMIN_AUTH_POST_SUCCESS                = "ADMIN_AUTH_POST_SUCCESS";
export const ADMIN_AUTH_POST_FAIL                   = "ADMIN_AUTH_POST_FAIL";
export const ADMIN_LOGOUT                           = "ADMIN_LOGOUT";

export const CREATE_ADMIN_USER                      = "CREATE_ADMIN_USER";

export const ADMIN_GET_ALL_REGISTERED_USERS_NUMBERS = "ADMIN_GET_ALL_REGISTERED_USERS_NUMBERS";

export const ADMIN_VALID_TOKEN                      = "ADMIN_VALID_TOKEN";

export const ADMIN_CHANGE_DP                        = "ADMIN_CHANGE_DP";

export const ADMIN_GET_INFO                         = "ADMIN_GET_INFO";
export const ADMIN_GET_INFO_START                   = "ADMIN_GET_INFO_START";
export const ADMIN_GET_INFO_END                     = "ADMIN_GET_INFO_END";

export const ADMIN_SET_INFO                         = "ADMIN_SET_INFO";
export const ADMIN_SET_INFO_START                   = "ADMIN_SET_INFO_START";
export const ADMIN_SET_INFO_END                     = "ADMIN_SET_INFO_END";