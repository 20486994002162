import React, {Component} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';

import * as PROJ_CONST from '../../../config/constants';

class IOSBox extends Component {
    state = {
        iosUser : "--"
    }

    getIOSBoxValue = () => {
        axios
            .get(
                PROJ_CONST.API_IOS_BOX_DASHBOARD,
                {
                    headers : {
                        "x-auth-token" : this.props.adminAuthState.token
                    }
                }
            )
            .then(response => {
                
                this.setState({
                    iosUser : response.data
                })
            })
            .catch(e => console.log('error', e))
    }

    componentDidMount = () => {
        this.getIOSBoxValue();
    }

    render() {
        return(
            <h3 className={this.props.cn}>
                {this.state.iosUser}
            </h3>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        adminAuthState: state.adminAuthReducer
    }
}

export default connect(mapStateToProps)(IOSBox);