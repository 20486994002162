
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import MoreHorizSharp from "@material-ui/icons/MoreHorizSharp";
import Accessibility from '@material-ui/icons/Accessibility';
import People from '@material-ui/icons/People';

// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";

// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import TableList from "views/TableList/TableList.jsx"; 
import Typography from "views/Typography/Typography.jsx";
import Icons from "views/Icons/Icons.jsx";
import Maps from "views/Maps/Maps.jsx";
import NotificationsPage from "views/Notifications/Notifications.jsx";
import ChangePassword from "views/ChangePassword/ChangePassword.jsx";
import Profession from "views/Profession/form.jsx";
import UserList from "views/UserProfile/UserList.jsx";
import UserDetail from "views/UserProfile/UserDetail.jsx";

// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.jsx";
// core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.jsx"; 

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    sidebar: true
  },
  {
    path: "/user",
    name: "User Profile",
    rtlName: "User Profile",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
    sidebar: false
  },
  {
    path: "/change-password",
    name: "Change Password",
    rtlName: "Change Password",
    icon: MoreHorizSharp,
    component: ChangePassword,
    layout: "/admin", 
    sidebar: false
  },
  {
    path: "/registered",
    name: "Registered Users",
    rtlName: "Registered Users",
    icon: People,
    component: UserList,
    layout: "/admin",
    sidebar: true
  },
  {
    path: "/register/:email",
    name: "Registered User Details",
    rtlName: "Registered User Details",
    icon: People,
    component: UserDetail,
    layout: "/admin",
    sidebar: false
  },
  {
    path: "/add-profession",
    name: "Professions",
    rtlName: "Professions",
    icon: Accessibility,
    component: Profession,
    layout: "/admin",
    sidebar: true
  },
  {
    path: "/table",
    name: "Table List",
    rtlName: "Table List",
    icon: "content_paste",
    component: TableList,
    layout: "/admin",
    sidebar: false
  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "Typography",
    icon: LibraryBooks,
    component: Typography,
    layout: "/admin",
    sidebar: false
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "Icons",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin",
    sidebar: false
  },
  {
    path: "/maps",
    name: "Maps",
    rtlName: "Maps",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
    sidebar: false
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "Notifications",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
    sidebar: false
  },
  {
    path: "/register",
    name: "Registered Users",
    rtlName: "Registered Users",
    icon: People,
    component: UserList,
    layout: "/admin",
    sidebar: false
  }
];

export default dashboardRoutes;
