import React from "react";
import axios from "axios";

import CardBody from "components/Card/CardBody.jsx"
import CardFooter from "components/Card/CardFooter.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import People from "@material-ui/icons/People";

import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import * as PROJ_CONST from "config/constants";


class ChangePasswordForm extends React.Component {
    state = {
        token : JSON.parse(localStorage.state).adminAuthReducer.token,
        username : JSON.parse(localStorage.state).adminAuthReducer.admin_username,
        opassword: '',
        password : '',
        cpassword: '',
        validateMsg: false,
        validationMsg: '',
        successMsg: ''
    }

    opassword = (e) => {
        this.setState({
            opassword: e.target.value
        })
    }

    getPassword = (e) => {
        this.setState({
            password: e.target.value
        },
        () => {
            if(this.state.cpassword !== this.state.password) {
                this.setState({
                    validateMsg: true
                })
            }
            else {
                this.setState({
                    validateMsg: false
                })
            }
        });
        
    }

    getCPassword = (e) => {
        
        this.setState({
            cpassword: e.target.value
        }, 
        () => {
            if(this.state.cpassword !== this.state.password) {
                this.setState({
                    validateMsg: true
                })
            }
            else {
                this.setState({
                    validateMsg: false
                })
            }
        })

        
    }

    submitForm = (e) => {
        
        e.preventDefault();
        
        const data = {
            username: this.state.username,
            opassword: this.state.opassword,
            password: this.state.password
        }

        if(this.state.password !== this.state.cpassword) {
            this.setState({
                validationMsg: "New password and confirm password do not match"
            }, () => {
                setTimeout(() => {
                    this.setState({
                        validationMsg: ''
                    })
                }, 4000)
            })
        }
        else if(this.state.opassword === "") {
            this.setState({
                validationMsg: "Current password is required"
            }, () => {
                setTimeout(() => {
                    this.setState({
                        validationMsg: ''
                    })
                }, 4000)
            })
        }
        else {
            axios
                .post(
                    PROJ_CONST.API_ADMIN_CHANGE_PWD,
                    data,
                    {
                        "headers" : {
                            "x-auth-token": this.state.token
                        }
                    }
                )
                .then(res => {
                    
                    if(res.data.msg) {
                        this.setState({
                            successMsg: res.data.msg
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    successMsg: ""
                                })
                            }, 4000)
                        })
                    }

                    if(res.data.errmsg) {
                        this.setState({
                            validationMsg: res.data.errmsg
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    validationMsg: ""
                                })
                            }, 4000)
                        })
                    }
                    
                })
                .catch(e => console.log(e))
        }

        

        
    }

    render() {
        return (
            <div>
                
                <CardBody>
                    {
                        (this.state.validationMsg !== "") ? <SnackbarContent message={this.state.validationMsg} color="danger"/> : ""
                    }

                    {
                        (this.state.successMsg !== "") ? <SnackbarContent message={this.state.successMsg} color="success"/> : ""
                    }
                    

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText={"Username: "+JSON.parse(localStorage.state).adminAuthReducer.admin_username}
                                id="username-disabled"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    disabled: true,
                                    endAdornment: (
                                        <InputAdornment position="start">
                                          <People />
                                        </InputAdornment>
                                      )
                                }}
                                
                            />
                        </GridItem>
                        
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            labelText="Current Password"
                            id="pwd"
                            passwordType="password"
                            formControlProps={{
                                fullWidth: true,
                                onChange: this.opassword,
                                
                            }}

                        />
                        </GridItem>
                        
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="New Password"
                                id="npwd"
                                passwordType="password"
                                formControlProps={{
                                    fullWidth: true,
                                    onChange: this.getPassword
                                }}
                            />
                        </GridItem>
                
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            labelText="Confirm Password"
                            id="cpwd"
                            passwordType="password"
                            formControlProps={{
                                fullWidth: true,
                                onChange: this.getCPassword
                            }}
                            error={this.state.validateMsg}
                        />
                        </GridItem>
                        
                    </GridContainer>
              
                </CardBody>
                <CardFooter>
                    <Button color="primary" onClick={this.submitForm}>Change Password</Button>
                </CardFooter>
            </div>
        )
    }
}

export default ChangePasswordForm;