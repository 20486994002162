import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import * as PROJ_CONST from '../../../config/constants';

import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import fb_icon_24 from 'assets/img/fb-24.png';
import google_icon_24 from 'assets/img/google-24.png';
import email_icon_24 from 'assets/img/email-24.png';

class UserList5 extends React.Component {

    state = {
        latestResult: false,
        dataInit: ""
    }

    componentDidMount = () => {
        this.getLatestUsers()
    }

    getLatestUsers = () => {
        if(!this.props.adminAuthState.token) {
            return <></>
        }
        else {
            axios
                .get(
                    PROJ_CONST.API_USER_LIST_LATEST+"/"+1+"/dashboard",
                    {
                        headers : {
                            "x-auth-token" : this.props.adminAuthState.token
                        }
                    }
                )
                .then(response => {

                    var results = response.data.map(obj => {
                        return Object.values(obj);
                    })

                    var list = [];

                    for(var i=0; i<results.length; i++) {

                        var socioIcon = "";

                        if(results[i][0] === "fb") {
                            socioIcon = <img src={fb_icon_24} alt="FB Users" width={18} height={18} />;
                        }
                        else if(results[i][0] === "google") {
                            socioIcon = <img src={google_icon_24} alt="Google Users" width={16} height={16} />;
                        }
                        else {
                            socioIcon = <img src={email_icon_24} alt="Email Users" width={16} height={12} />;
                        }

                        list.push([
                            results[i][3],
                            results[i][4],
                            (results[i][2]) ? "Yes" : "No",
                            (results[i][1]) ? "Yes" : "No",
                            // results[i][0],
                            socioIcon
                        ])
                    }

                    this.setState({
                        dataInit: list
                    },
                    () => {
                        this.setState({
                            latestResult: true
                        })
                    })
                })
                .catch( err => {
                    console.log("api failed->",PROJ_CONST.API_USER_LIST_LATEST,"->", err)
                })
        }
    }

    render() {
        if(!this.state.latestResult) {
            return(
                <Card>
                    <CardHeader color="warning">
                        <h4 className={this.props.cardTitleWhite}>User List</h4>
                        <p className={this.props.cardCategoryWhite}>Latest Users</p>
                    </CardHeader>
                    <CardBody>
                        <h1>User Loading ...</h1>
                    </CardBody>
                </Card>
            )
        }
        else {
            return(
            
                <Card>
                    <CardHeader color="warning">
                        <h4 className={this.props.cardTitleWhite}>User List</h4>
                        <p className={this.props.cardCategoryWhite}>Latest Users</p>
                    </CardHeader>
                    <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Full Name", "Email", "isVerified", "isAcitve", "Method"]}
                            tableData={this.state.dataInit}
                        />
                    </CardBody>
                </Card>
                
            )
        }
        
    }
}

const mapStateToProps = state => {
    return {
        adminAuthState: state.adminAuthReducer
    }
}

export default connect(mapStateToProps)(UserList5);