import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import * as PROJ_CONST from '../../../config/constants';

import fb_icon_24 from 'assets/img/fb-24.png';
import google_icon_24 from 'assets/img/google-24.png';
import email_icon_24 from 'assets/img/email-24.png';

class RegisteredTypeUsers extends React.Component {

    state = {
        fb_user: "--",
        google_user: "--",
        email_user: "--"
    }

    componentDidMount = () => {
        this.getAllTypeRegisteredUser();
    }

    getAllTypeRegisteredUser = () => {
        if(this.props.adminAuthState.token === "") {
            return null;
        }
        else {
            axios
                .get(
                    PROJ_CONST.API_USER_REGISTERED_TYPE,
                    {
                        headers: {
                            "x-auth-token" : this.props.adminAuthState.token
                        }
                    }
                )
                .then(response => {
                    for(var i=0; i<response.data.length; i++) {
                        if(response.data[i].registeredType === "google") {
                            this.setState({
                                google_user: response.data[i].countData
                            })
                        }
                        else if(response.data[i].registeredType === "fb") {
                            this.setState({
                                fb_user: response.data[i].countData
                            })
                        }
                        else {
                            this.setState({
                                email_user: response.data[i].countData
                            })
                        }
                    }
                })
                .catch(error => {
                    console.log('api getAllTypeRegisteredUser error->', error)
                })
        }
    }
    
    render() {
        return(
            <div>
                <p className={this.props.cardCategory}>Registered Using</p>
                <h3 className={this.props.cardTitle}>
                    <img src={fb_icon_24} alt="FB Users" style={{marginRight:10}} width={22} height={22} /><small>{this.state.fb_user}</small>
                </h3>
                <h3 className={this.props.cardTitle}>
                    <img src={google_icon_24} alt="Google Users" style={{marginRight:10}} width={20} height={20} /><small>{this.state.google_user}</small>
                </h3>
                <h3 className={this.props.cardTitle}>
                    <img src={email_icon_24} alt="Email Users" style={{marginRight:10}} width={20} height={16} /><small>{this.state.email_user}</small>
                </h3>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        adminAuthState: state.adminAuthReducer
    }
}

export default connect(mapStateToProps)(RegisteredTypeUsers);