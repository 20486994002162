import React, {Component} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';

import * as PROJ_CONST from '../../../config/constants';

class AndroidBox extends Component {
    state = {
        androidUser : "--"
    }

    getAndroidBoxValue = () => {
        axios
            .get(
                PROJ_CONST.API_ANDROID_BOX_DASHBOARD,
                {
                    headers : {
                        "x-auth-token" : this.props.adminAuthState.token
                    }
                }
            )
            .then(response => {
                

                this.setState({
                    androidUser : response.data
                })
            })
            .catch(e => console.log('error', e))
    }

    componentDidMount = () => {
        this.getAndroidBoxValue();
    }

    render() {
        return(
            <h3 className={this.props.cn}>
                {this.state.androidUser}
            </h3>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        adminAuthState: state.adminAuthReducer
    }
}

export default connect(mapStateToProps)(AndroidBox);