
import React from "react";
import {connect} from 'react-redux';
import axios from "axios";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CheckCircleSharp from "@material-ui/icons/CheckCircleSharp";
import Cancel from "@material-ui/icons/Cancel";
import VerifiedUser from "@material-ui/icons/VerifiedUser"
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import MobileFriendlySharp from "@material-ui/icons/MobileFriendlySharp";

import * as PROJ_CONST from '../../config/constants';

import UserPic from './mini/userPic.jsx';

import {adminLogoutAct} from '../../rdx/actions/adminAuthActions';

const styles = {
    cardCategoryWhite: {
        color: "#FFF",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    headings: {
        color: '#1b1b1b'
    },
    subheadings: {
        fontSize: 18,
        color: '#1b1b1b'
    }
    
};

class UserDetail extends React.Component {

    state = {
        details: false,
        error: false,
        errorCode: 0,
        errorMsg: 'Loading ...',
        dpurl: '',
        updatedProfile: true,
        cardsInfo: false,
        cardsData: {},
        cardsDataLength: 0,
        logourl: '',
        qrurl: ''
    }

    componentDidMount = () => {

        if(this.props.match.params.email !== "") {
            this.setState({
                email: this.props.match.params.email
            },
            () => {
                axios
                    .post(
                        PROJ_CONST.API_ADMIN_GET_USERDETAIL,
                        {
                            email: this.state.email
                        },
                        {
                            headers : {
                                "x-auth-token" : this.props.adminAuthState.token
                            }
                        }
                    )
                    .then(UserDetail => {
                        console.log('Response-->', UserDetail)
                        if(UserDetail.data.success === false) {
                            
                            if(UserDetail.data.errorCode === 1) {
                                this.setState({
                                    details: false,
                                    error: true,
                                    errorCode: UserDetail.data.errorCode,
                                    errorMsg: 'Something is wrong while fetching data. Please Try Again.'
                                })
                            }

                            if(UserDetail.data.errorCode === 2) {
                                this.setState({
                                    details: false,
                                    error: true,
                                    errorCode: UserDetail.data.errorCode,
                                    errorMsg: 'User not found in server'
                                })
                            }
                        }
                        else {
                            this.setState({
                                details: UserDetail.data.data,
                                dpurl: UserDetail.data.dpurl,
                                error: false,
                                errorCode: 3,
                                errorMsg: '',
                                updatedProfile: UserDetail.data.uProfile,
                                cardsInfo: UserDetail.data.cInfo,
                                cardsData: UserDetail.data.cData,
                                cardsDataLength: UserDetail.data.cData.length,
                                logourl: UserDetail.data.logourl,
                                qrurl: UserDetail.data.qrurl
                            })

                        }
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status === 401 && err.response !== undefined) {
                            this.props.adminLogoutActProp();
                        }
                    })
            });
        }
        else {
            this.props.history.goBack();
        }

    }

    render() {
        console.log(this.state)
        if(this.state.errorCode !== 3) {
            return(
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 style={styles.cardTitleWhite}>Info</h4>
                                </CardHeader>
                                <CardBody>
                                    <h2>{this.state.errorMsg}</h2>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            )
        }
        else {
            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <Card>
                                <CardHeader color="info" stats icon>
                                    {
                                        (this.state.updatedProfile)
                                        ?
                                        <CardIcon color="info">
                                            <UserPic url={this.state.dpurl} name={this.state.details[0].name} file={this.state.details[0].profile.dp} />
                                        </CardIcon>
                                        :
                                        <></>
                                    }
                                    <h1 style={styles.headings}>{this.state.details[0].email}</h1>
                                    <h2 style={styles.headings}>{this.state.details[0].name}</h2>
                                    <h2 style={styles.headings}>{this.state.details[0].registration_type}</h2>
                                </CardHeader>
                            </Card>
                        </GridItem>

                        <GridItem xs={12} sm={6} md={6}>
                            <Card>
                                <CardHeader color="info" stats icon>
                                    <CardIcon color="info">
                                        <MobileFriendlySharp />
                                    </CardIcon>
                                    <h2 style={styles.headings}>Active : {(this.state.details[0].isActive) ? <CheckCircleSharp color="primary" /> : <Cancel color="error" />}</h2>
                                    <h2 style={styles.headings}>Verified : {(this.state.details[0].isVerified) ? <VerifiedUser color="primary" /> : <Cancel color="error" />}</h2>
                                    <h2 style={styles.headings}>{this.state.details[0].registeredDate}</h2>
                                </CardHeader>
                            </Card>
                        </GridItem>
                        {
                            (this.state.updatedProfile)
                            ?
                            <GridItem xs={12} sm={6} md={6}>
                                <Card>
                                    <CardHeader color="primary">
                                        <h4 style={styles.cardTitleWhite}>Profile</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <h2>{this.state.details[0].profile.street} {this.state.details[0].profile.city}</h2>
                                        <h2>{this.state.details[0].profile.state}</h2>
                                        <h2>{this.state.details[0].profile.country}</h2>
                                        <h2>{this.state.details[0].profile.mobile_number}</h2>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            :
                            <></>
                        }

                        <GridItem xs={12} sm={6} md={6}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 style={styles.cardTitleWhite}>Device Details</h4>
                                </CardHeader>
                                <CardBody>
                                    <h2 style={styles.subheadings}>{this.state.details[0].registrationDevice.os} {this.state.details[0].registrationDevice.os_version}</h2>
                                    <h2 style={styles.subheadings}>Device Name: {this.state.details[0].registrationDevice.device_name}</h2>
                                    <h2 style={styles.subheadings}>Device Model: {this.state.details[0].registrationDevice.device_model}</h2>
                                    <h2 style={styles.subheadings}>Device Id: {this.state.details[0].registrationDevice.device_id}</h2>
                                    <h2 style={styles.subheadings}>Device Ip: {this.state.details[0].registrationDevice.device_ip}</h2>
                                    <h2 style={styles.subheadings}>MAC Address: {this.state.details[0].registrationDevice.device_mac_address}</h2>
                                    <h2 style={styles.subheadings}>Device Country: {this.state.details[0].registrationDevice.device_country}</h2>
                                    <h2 style={styles.subheadings}>TZ: {this.state.details[0].registrationDevice.local_timezone}</h2>
                                    <h2 style={styles.subheadings}>Long: {this.state.details[0].registrationDevice.longitude}</h2>
                                    <h2 style={styles.subheadings}>Lat: {this.state.details[0].registrationDevice.latitude}</h2>
                                </CardBody>
                            </Card>
                        </GridItem>

                        {
                            (this.state.cInfo === false)
                            ?
                            <></>
                            :

                            <GridItem xs={12} sm={12} md={12}>
                                {
                                    this.state.cardsData.map((card, key) => {
                                        return(
                                            <Card key={key}>
                                                <CardHeader color="primary">
                                                    <h4 style={styles.cardTitleWhite}>Card Details</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    
                                                    <h2 style={styles.subheadings}>{card.cardCode}</h2>
                                                    <h2 style={styles.subheadings}>Private: {(card.isPrivate) ? "Yes" : "No"}</h2>
                                                    <h2 style={styles.subheadings}>Full Name: {card.personalInfo.title.concat(" ",card.personalInfo.firstName, " ", card.personalInfo.lastName)}</h2>
                                                    <h2 style={styles.subheadings}>Organization: {card.organizationDetails.orgName.concat("/",card.organizationDetails.address, "/", card.organizationDetails.city, "/", card.organizationDetails.state, "/", card.organizationDetails.country)}</h2>
                                                    <h2 style={styles.subheadings}>Contact Info: {card.contactInfo.email.concat("/",card.contactInfo.mobileNumber, "/", card.contactInfo.phoneNumber, "/", card.contactInfo.website)}</h2>
                                                    <h2 style={styles.subheadings}>About: {card.about}</h2>
                                                    <h2 style={styles.subheadings}>Tags: 
                                                        {
                                                            card.tags.map(tag => {
                                                                return(
                                                                    tag.concat("/")
                                                                );
                                                            })
                                                        }
                                                    </h2>

                                                    {
                                                        (card.organizationDetails.logo !== "")
                                                        ?
                                                        <img src={this.state.logourl.concat(card.organizationDetails.logo)} width={200} height={200} alt="Logo" />
                                                        :
                                                        <></>
                                                    }

                                                    <img src={this.state.qrurl.concat(card.cardCode,".png")} width={200} height={200} alt="QR" />
                                                    
                                                        
                                                </CardBody>
                                            </Card>
                                        );
                                    })
                                }
                                
                            </GridItem>
                        }
                    </GridContainer>
                </div>
              );
        }
        
    }
}

const mapStateToProps = state => {
    return {
        adminAuthState: state.adminAuthReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        adminLogoutActProp: () => {
            dispatch(adminLogoutAct())
        }
    }
}

UserDetail.propTypes = {
  classes: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserDetail));
