

import axios from 'axios';
import * as PROJ_CONST from "../../config/constants";

import {
    CREATE_ADMIN_USER, 
    ADMIN_AUTH_POST_SUCCESS, 
    ADMIN_AUTH_POST_FAIL,
    ADMIN_LOGOUT,
    ADMIN_CHANGE_DP,
    ADMIN_GET_INFO,
    ADMIN_GET_INFO_END,
    ADMIN_GET_INFO_START,
    ADMIN_SET_INFO,
    ADMIN_SET_INFO_START,
    ADMIN_SET_INFO_END
} from './types';

export const adminAuthPostAct = (loginData) => {
    //While using thunk, this method should return function.
    return (dispatch) => {
        
        const headers = {
            "content-type": "application/json"
        }
        //make axios call and validate login and get token
        axios
            .post(PROJ_CONST.API_ADMIN_AUTH_URL,loginData,headers)
            .then(adminUserData => {
                
                var payloadData = "";

                if(adminUserData.data.token) {
                    
                    payloadData = {
                        validationMsg: '',
                        open: false,
                        loginSuccessMsg: adminUserData.data.msg+" Redirecting to dashboard...",
                        auth: true,
                        token: adminUserData.data.token,
                        admin_username: adminUserData.data.adminUserDetails.username,
                        admin_name: adminUserData.data.adminUserDetails.name,
                        tokenUpdate: true,
                        adminDP: adminUserData.data.adminUserDetails.dp,
                    };

                    dispatch({
                        type: ADMIN_AUTH_POST_SUCCESS,
                        payload: payloadData
                    });

                }
                else {
                    payloadData = {
                        validationMsg: adminUserData.data.msg,
                        open: false,
                        loginSuccessMsg: "",
                        auth: false,
                        token: "",
                        admin_username: "",
                        admin_name: "",
                        tokenUpdate: false,
                        email: ""
                    };

                    dispatch({
                        type: ADMIN_AUTH_POST_FAIL,
                        payload: payloadData
                    });
                }
                
            })
            .catch(err => console.log(err))

        
    }
};

export const adminLogoutAct = () => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_LOGOUT
        })
    }
}

export const createAdminUserAct = () => {
    return {
        type: CREATE_ADMIN_USER
    };
};

export const changeAdminDPAct = (formDataDP) => {
    var payloadData = "";
    
    
    return (dispatch, getState) => {
        const state = getState();

        axios
            .post(
                PROJ_CONST.API_ADMIN_CHANGE_DP,
                formDataDP,
                {
                    headers: {
                        'Content-type' : 'multipart/form-data',
                        'x-auth-token' : state.adminAuthReducer.token
                    }
                }
            )
            .then(resp => {
                
                if(resp.data.msg) {
                    payloadData = {
                        adminDP: resp.data.dp_url
                    }
                    dispatch({
                        type: ADMIN_CHANGE_DP,
                        payload: payloadData
                    })
                    
                }
                
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const getAdminInfoAct = () => {

    return (dispatch, getState) => {
        const state = getState();

        dispatch(getAdminInfoStartAct());
        
        axios
            .get(
                PROJ_CONST.API_ADMIN_GET_INFO,
                {
                    headers: {
                        "x-auth-token": state.adminAuthReducer.token
                    }
                }
            )
            .then(resp => {
                
                const payloadData = {
                    
                    admin_username: resp.data.adminUserDetails.username,
                    admin_name: resp.data.adminUserDetails.name,
                    admin_lname: resp.data.adminUserDetails.lname,
                    admin_phone: resp.data.adminUserDetails.phone,
                    admin_mobile: resp.data.adminUserDetails.mobile,
                    admin_email: resp.data.adminUserDetails.email
                }

                
                
                dispatch({
                    type: ADMIN_GET_INFO,
                    payload: payloadData
                });

                dispatch(getAdminInfoEndAct());

                
                
                
            })
            .catch(error => {
                
                console.log(error)
            })
    }
}

export const getAdminInfoEndAct = () => ({
    type: ADMIN_GET_INFO_END
})

export const getAdminInfoStartAct = () => ({
    type: ADMIN_GET_INFO_START
})

export const setAdminInfoAct = (postData) => {

    return (dispatch, getState) => {
        const state = getState();

        dispatch(setAdminInfoStartAct());
        
        axios
            .post(
                PROJ_CONST.API_ADMIN_SET_INFO,
                postData,
                {
                    headers: {
                        "x-auth-token": state.adminAuthReducer.token
                    }
                }
            )
            .then(resp => {

                setTimeout(() => {



                    if(resp.data.msg) {
                        const payloadData = {
                            admin_name: postData.admin_name,
                            admin_lname: postData.admin_lname,
                            admin_phone: postData.admin_phone,
                            admin_mobile: postData.admin_mobile,
                            admin_email: postData.admin_email,
                        }
    
                        dispatch({
                            type: ADMIN_SET_INFO,
                            payload: payloadData
                        })
                    }




                }, 3000) // for production remove this setTimeout and use only if condition.
                
                
            })
            .catch(err => {
                console.log(err)
            })

    }
}

export const setAdminInfoStartAct = () => ({
    type: ADMIN_SET_INFO_START
})

export const setAdminInfoEndAct = () => {
    return (dispatch) => {
        dispatch({type: ADMIN_SET_INFO_END})
    }
}