import {
    createStore,
    applyMiddleware,
    compose
} from 'redux';

import thunk from 'redux-thunk';

import rootReducer from './reducers';

// for Development 
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// for Production
const composeEnhancers = compose;

//Persisting the states from browser refresh using localstorage and redux sync'ing

function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state',serializedState);
        
    }
    catch(e) {
        console.log(e);
    }
}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state');

        if(serializedState === null) {
            return undefined;
        }
        
        return JSON.parse(serializedState);
        
    }
    catch(e) {
        console.log(e);
    }
}

const initState = loadFromLocalStorage();

// for Development
// const store = createStore(
//     rootReducer, 
//     initState, 
//     composeEnhancers(
//         applyMiddleware(thunk)
//     )
// );

// for Production
const store = createStore(
    rootReducer,
    initState,
    applyMiddleware(thunk)
);


store.subscribe(() => {
    saveToLocalStorage(store.getState())
})

export default store;