const API_URL = "https://api.digivcards.com";

export const PROJECT_NAME = "DigiVCards";

export const PER_PAGE = 10;

export const API_REGISTERED_USER_COUNT_URL = API_URL+"/api/admin/allRegUsers";
export const API_ANDROID_BOX_DASHBOARD = API_URL+"/api/admin/adroidBoxDashboard";
export const API_IOS_BOX_DASHBOARD = API_URL+"/api/admin/iOSBoxDashboard";
export const API_USER_REGISTERED_TYPE = API_URL+"/api/admin/registeredType";
export const API_USER_LIST_LATEST  = API_URL+"/api/admin/latestUsers";


export const API_ADMIN_AUTH_URL       = API_URL+"/api/admin/auth";
export const API_ADMIN_VALID_TOKEN    = API_URL+"/api/admin/updateToken";
export const API_ADMIN_VALID_AUTH     = API_URL+"/api/admin/checkAuth";
export const API_ADMIN_CHANGE_PWD     = API_URL+"/api/admin/change-password";
export const API_ADMIN_CHANGE_DP      = API_URL+"/api/admin/change-dp";
export const API_ADMIN_GET_INFO       = API_URL+"/api/admin/info";
export const API_ADMIN_SET_INFO       = API_URL+"/api/admin/info";
export const API_ADMIN_SET_PROFESSION = API_URL+"/api/admin/setProfession";
export const API_ADMIN_GET_PROFESSION = API_URL+"/api/admin/getProfession";
export const API_ADMIN_GET_USERDETAIL = API_URL+"/api/admin/getUserDetails";