import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import * as PROJ_CONST from '../../../config/constants';

class AllRegisteredUserNumberBox extends React.Component {

    state = {
        classname: "",
        n: '--'
    }

    getAllRegisteredUsers = () => {

        if(this.props.adminAuthState.token === "") {
            return null;
        }
    
        axios
          .get(
              PROJ_CONST.API_REGISTERED_USER_COUNT_URL,
              {
                headers: {  
                    "x-auth-token" : this.props.adminAuthState.token
                }
              }
            )
          .then(res => {
            
            this.setState({
                n: res.data
            })
            
          })
          .catch(err => {
            console.log('error calling axios: ', err)
          });
    
    }

    componentDidMount = () => {
        this.getAllRegisteredUsers();

        this.setState({
            classname: this.props.cn
        })
    }


    render() {
        return (
            <h3 className={this.state.classname}>{this.state.n}</h3>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        adminAuthState: state.adminAuthReducer
    }
}

export default connect(mapStateToProps)(AllRegisteredUserNumberBox)