import React from 'react';
import {Redirect} from 'react-router-dom';

class Profile extends React.Component{
    state = {
        redirectTo: false
    }
    redirect = () => {
        this.setState({
            redirectTo: true
        })
    }
    render() {
        if(this.state.redirectTo) {
            return <Redirect to="/admin/user" />
        }
        return(
            <span onClick={this.redirect}>Profile</span>
        );
    }
}

export default Profile;