import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import People from "@material-ui/icons/People";
import AlternateEmailOutlined from "@material-ui/icons/AlternateEmailOutlined"
import CallRounded from "@material-ui/icons/CallRounded"
import PhonelinkRing from "@material-ui/icons/PhonelinkRing"
import Contacts from "@material-ui/icons/Contacts"

import {
    getAdminInfoAct, 
    setAdminInfoAct, 
    setAdminInfoEndAct
} from '../../../rdx/actions/adminAuthActions';


class UpdateForm extends Component {

    constructor(props) {
        super(props)

        if(this.props.adminAuthReducerProps.admin_mobile !== undefined && this.props.adminAuthReducerProps.admin_mobile !== "") {

            this.state = {
                validationMsg: "",
                savingMsg: "",
                admin_name: this.props.adminAuthReducerProps.admin_name,
                admin_lname: this.props.adminAuthReducerProps.admin_lname,
                admin_phone: this.props.adminAuthReducerProps.admin_phone,
                admin_mobile: this.props.adminAuthReducerProps.admin_mobile,
                admin_email: this.props.adminAuthReducerProps.admin_email,
                admin_username: this.props.adminAuthReducerProps.admin_username
            }

        }
        else {
            this.state = {
                validationMsg: "",
                savingMsg: "",
                admin_name: "-",
                admin_lname: "-",
                admin_phone: "-",
                admin_mobile: "-",
                admin_email: "-",
                admin_username: "-"
            }

        }
    }

    componentDidUpdate(prevState) {

        if(prevState.adminAuthReducerProps.admin_email !== this.props.adminAuthReducerProps.admin_email) {
            this.setState({
                admin_name: this.props.adminAuthReducerProps.admin_name,
                admin_lname: this.props.adminAuthReducerProps.admin_lname,
                admin_phone: this.props.adminAuthReducerProps.admin_phone,
                admin_mobile: this.props.adminAuthReducerProps.admin_mobile,
                admin_email: this.props.adminAuthReducerProps.admin_email,
                admin_username: this.props.adminAuthReducerProps.admin_username
            })
        }
        
    }

    

    componentDidMount() {
        this.props.getAdminInfoActProp();
    }

    getEmail = (e) => {
        this.setState({
            admin_email: e.target.value
        })
    }

    getFname = (e) => {
        this.setState({
            admin_name: e.target.value
        })
    }

    getLname = (e) => {
        this.setState({
            admin_lname: e.target.value
        })
    }

    getPhone = (e) => {
        this.setState({
            admin_phone: e.target.value
        })
    }

    getMobile = (e) => {
        this.setState({
            admin_mobile: e.target.value
        })
    }

    submitForm = (e) => {
        e.preventDefault();

        if(
            this.state.admin_email === "" ||
            this.state.admin_name === "" ||
            this.state.admin_lname === "" ||
            this.state.admin_mobile === ""
            
        ) {
            this.setState({
                validationMsg : "Email, Mobile, First Name & Last Name are mandatory. "
            }, () => {
                setTimeout(() => {
                    this.setState({
                        validationMsg: ""
                    })
                }, 3000);
            })
        }
        else {
            
            this.setState({
                savingMsg: "Saving Information. Please wait ...."
            }, () => {
                setTimeout(() => {
                    this.setState({
                        validationMsg: ""
                    })
                }, 2000);
            });

            const formData = {
                admin_name: this.state.admin_name,
                admin_lname: this.state.admin_lname,
                admin_phone: this.state.admin_phone,
                admin_mobile: this.state.admin_mobile,
                admin_email: this.state.admin_email,
            }

            this.props.setAdminInfoActProp(formData);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if(nextProps.adminAuthReducerProps.admin_profile_class === "success") {
            setTimeout(() => {
                nextProps.setAdminInfoEndActProp()
            }, 3000)
        }

        return true;
    }
    
    render() {

        const styles = {
            cardCategoryWhite: {
              color: "#FFF",
              margin: "0",
              fontSize: "14px",
              marginTop: "0",
              marginBottom: "0"
            },
            cardTitleWhite: {
              color: "#FFFFFF",
              marginTop: "0px",
              minHeight: "auto",
              fontWeight: "300",
              fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
              marginBottom: "3px",
              textDecoration: "none"
            }
        };

        if(this.props.adminAuthReducerProps.admin_email !== "") {
            return(
            
                <Fragment>
                    {
                        (this.props.adminAuthReducerProps.admin_profile_class !== "") ? 
                            (
                                <SnackbarContent 
                                    message={this.props.adminAuthReducerProps.admin_profile} 
                                    color={this.props.adminAuthReducerProps.admin_profile_class} 
                                />
                            )
                            
                        : 
                            ""
                    }
                    {
                        (this.state.validationMsg !== "") 
                            ?
                            (
                                <SnackbarContent 
                                    message={this.state.validationMsg} 
                                    color="danger" 
                                />
                            )
                            :
                            ""
                            
                        
                    }
                    <CardHeader color="primary">
                        <h4 style={styles.cardTitleWhite}>Edit Profile</h4>
                        <p style={styles.cardCategoryWhite}>Complete your profile</p>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText={"Username: "+this.state.admin_username}
                                    id="username"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        
                                        disabled: true,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                            <People />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Email"
                                    id="email-address"
                                    formControlProps={{
                                        fullWidth: true,
                                        onChange:this.getEmail
                                    }}
                                    inputProps={{
                                        value:this.state.admin_email,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                            <AlternateEmailOutlined style={{color: "#CCC"}} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="First Name"
                                    id="first-name"
                                    formControlProps={{
                                        fullWidth: true,
                                        onChange: this.getFname
                                    }}
                                    inputProps={{
                                        value:this.state.admin_name,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                            <Contacts style={{color: "#CCC"}} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Last Name"
                                    id="last-name"
                                    formControlProps={{
                                        fullWidth: true,
                                        onChange: this.getLname
                                    }}
                                    inputProps={{
                                        value: this.state.admin_lname,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                            <Contacts style={{color: "#CCC"}} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Phone"
                                    id="Phone"
                                    formControlProps={{
                                        fullWidth: true,
                                        onChange: this.getPhone
                                    }}
                                    inputProps={{
                                        value: this.state.admin_phone,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                            <CallRounded style={{color: "#CCC"}} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Mobile"
                                    id="Mobile"
                                    formControlProps={{
                                        fullWidth: true,
                                        onChange: this.getMobile
                                    }}
                                    inputProps={{
                                        value: this.state.admin_mobile,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                            <PhonelinkRing style={{color: "#CCC"}} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            
                        </GridContainer>
                        
                    </CardBody>

                    <CardFooter>
                        <Button color="primary" onClick={this.submitForm} >Update Profile</Button>
                    </CardFooter>

                </Fragment>
            );
        }
        else {
            return(
                <Fragment>
                    <SnackbarContent 
                        message="Loading ..." 
                        color="primary" 
                    />
                </Fragment>
            )
        }

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAdminInfoActProp: () => {
            dispatch(getAdminInfoAct())
        },
        setAdminInfoActProp: (formData) => {
            dispatch(setAdminInfoAct(formData))
        },
        setAdminInfoEndActProp: () => {
            dispatch(setAdminInfoEndAct())
        }
    }
}

const mapStateToProps = (state) => {
    return {
        adminAuthReducerProps: state.adminAuthReducer
    }
    
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateForm);