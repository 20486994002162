import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import {Provider} from 'react-redux';
import store from './rdx/store';

// core components
import Admin from "layouts/Admin.jsx";
import Login from "./layouts/Login.jsx";

import "assets/css/material-dashboard-react.css?v=1.7.0";

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route exact path="/admin/login" component={Login} />
        <Route path="/admin" component={Admin} />

        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
