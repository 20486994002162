import React from "react";
import axios from "axios";

import CardBody from "components/Card/CardBody.jsx"
import CardFooter from "components/Card/CardFooter.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import ProfessionList from "views/Profession/list.jsx";

import * as PROJ_CONST from "config/constants";

class AddProfession extends React.Component {
    state = {
        token : JSON.parse(localStorage.state).adminAuthReducer.token,
        username : JSON.parse(localStorage.state).adminAuthReducer.admin_username,
        profession: '',
        validateMsg: false,
        validationMsg: '',
        successMsg: '',
        reload: true
    }

    profession = (e) => {
        this.setState({
            profession: e.target.value
        })
    }

    submitForm = (e) => {
        
        e.preventDefault();
        
        const data = {
            profession: this.state.profession
        }

        if(this.state.profession === "") {
            this.setState({
                validationMsg: "Profession cannot be empty."
            }, () => {
                setTimeout(() => {
                    this.setState({
                        validationMsg: ''
                    })
                }, 4000)
            })
        }
        else {
            axios
                .post(
                    PROJ_CONST.API_ADMIN_SET_PROFESSION,
                    data,
                    {
                        "headers" : {
                            "x-auth-token": this.state.token
                        }
                    }
                )
                .then(res => {
                    
                    if(res.data.success) {
                        this.setState({
                            successMsg: res.data.msg,
                            profession: '',
                            reload: false
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    successMsg: "",
                                    reload: true
                                })
                            }, 4000)
                        })
                    }
                })
                .catch(e => console.log(e)) 
        }
    }

    render() {
        return (
            <div>
                <CardBody>
                    {
                        (this.state.validationMsg !== "") ? <SnackbarContent message={this.state.validationMsg} color="danger"/> : ""
                    }

                    {
                        (this.state.successMsg !== "") ? <SnackbarContent message={this.state.successMsg} color="success"/> : ""
                    }
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Profession Name"
                                id="profession"
                                passwordType="text"
                                formControlProps={{
                                    fullWidth: true,
                                    onChange: this.profession,
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </CardBody>
                <CardFooter>
                    <Button color="primary" onClick={this.submitForm}>Add Profession</Button>
                </CardFooter>

                {
                    (this.state.reload) ? <ProfessionList /> : <div>Loading...</div>
                }

                
            </div>
            
        )
    }
}

export default AddProfession;