import React from 'react';
import axios from 'axios';

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import * as PROJ_CONST from "config/constants";

class ProfessionList extends React.Component {

    state = {
        token: JSON.parse(localStorage.state).adminAuthReducer.token,
        professionList: [],
        showData: false
    }

    componentDidMount = () => {
        this.getList();
    }

    getList = () => {

        axios
            .get(
                PROJ_CONST.API_ADMIN_GET_PROFESSION,
                {
                    "headers" : {
                        "x-auth-token": this.state.token
                    }
                }
            )
            .then(list => {

                var data = list.data.list;

                var results = data.map(obj => {
                    return Object.values(obj);
                });

                var allProfessionList = [];

                for(var count=0; count<results.length; count++) {
                    allProfessionList.push([count+1, results[count][1], (results[count][0]) ? "Yes" : "No"])
                }

                this.setState({
                    professionList: allProfessionList,
                    showData: true
                })

            })
            .catch(err => {
                console.log(err)
            });

    }

    render() {
        return(
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={styles.cardTitleWhite}>Profession List</h4>
                        </CardHeader>
                        <CardBody>
                            {
                                (this.state.showData)
                                ?
                                <Table
                                    tableHeaderColor="primary"
                                    tableHead={["SN", "Profession", "isActive"]}
                                    tableData={this.state.professionList}
                                />
                                :
                                <div>Loading ...</div>
                            }
                            
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

export default ProfessionList;