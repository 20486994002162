import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";

import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import avatar from "assets/img/faces/marc.jpg";

import { changeAdminDPAct } from 'rdx/actions/adminAuthActions';

class ProfileForm extends Component {
    state = {
        DP: '',
        DP_filename: '',
        validationMsg: '',
        avatar
    }

    componentDidMount() {
        if(this.props.adminAuthState.adminDP) {
            this.setState({
                avatar: "/upload/admin/dp/"+this.props.adminAuthState.adminDP
            })
        }
        else {
            this.setState({
                avatar
            })
        }

    }

    DPUplodHandler = e => {
        this.setState({
            DP: e.target.files[0],
            DP_filename: e.target.files[0].name
        })
    }

    saveDPHandler = e => {
        e.preventDefault();

        var newDPForm = new FormData();
        newDPForm.append('DP', this.state.DP);

        if(!this.state.DP) {
            this.setState({
                validationMsg: "Please select a file to upload."
            }, ()=>{
                setTimeout( () => {
                    this.setState({
                        validationMsg: ''
                    })
                },2000)
            })
            
        }
        else {
            this.props.adminChangeDPActProp(newDPForm);
        }
    }

    
    render() {
        return(
            <Fragment>
                {
                    (this.state.validationMsg !== "") ? <SnackbarContent message={this.state.validationMsg} color="danger"/> : ""
                }
                <CardAvatar profile>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img   
                            src={"/upload/admin/dp/"+this.props.adminAuthState.adminDP} 
                            alt="Profile Pic"
                            onClick={() => this.uploadFile.click()} 
                        />
                    </a>
                    <input
                        type="file"
                        style={{display:'none'}}
                        onChange={this.DPUplodHandler}
                        ref={uploadFile => this.uploadFile = uploadFile}
                    />
                </CardAvatar>

                <CardBody>
                    <h6 className={this.props.cnh6}>Change Profile Picture</h6>
                    
                    <Button color="primary" round onClick={this.saveDPHandler}>
                        Change Now
                    </Button>
                </CardBody>

            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminChangeDPActProp: (formData) => {
            dispatch(changeAdminDPAct(formData))
        },
    }
}

const mapStateToProps = (state) => {
    return {
        adminAuthState: state.adminAuthReducer
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);