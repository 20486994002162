import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import CardAvatar from "components/Card/CardAvatar.jsx";
import AccountCircle from '@material-ui/icons/AccountCircle';

class UserPic extends Component {
    state = {
        url: '',
        name: '',
        file: false
    }

    componentDidMount() {
        this.setState({
            url: this.props.url,
            name: this.props.name,
            file: this.props.file
        })
    }

    render() {
        if(this.state.file === "") {
            return <AccountCircle />
        }
        return(
            <Fragment>
                <CardAvatar profile>
                    <img   
                        src={this.state.url+this.state.file}
                        alt="Profile Pic"
                    />
                </CardAvatar>
            </Fragment>
        );
    }
}

export default connect()(UserPic);