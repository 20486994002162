

import {
    ADMIN_AUTH_POST_SUCCESS,
    ADMIN_AUTH_POST_FAIL,
    ADMIN_LOGOUT,
    ADMIN_CHANGE_DP,
    ADMIN_GET_INFO,
    ADMIN_GET_INFO_END,
    ADMIN_SET_INFO,
    ADMIN_SET_INFO_START,
    ADMIN_SET_INFO_END,
    ADMIN_GET_INFO_START
} from '../actions/types';

const initialState = {
    auth: false,
    token: '',
    admin_username: '',
    admin_name: '',
    admin_lname: '',
    admin_phone: '',
    admin_mobile: '',
    admin_email: '',
    validationMsg: '',
    open: false,
    loginSuccessMsg: "",
    tokenUpdate: false,
    adminDP: '',
    admin_profile: "",
    admin_profile_class: "",
    admin_profile_status: ""
}

export default function(state = initialState, action) {
    const pl = action.payload;
    switch(action.type) {
        case ADMIN_AUTH_POST_SUCCESS: {

            return state = {
                ...state,
                validationMsg: pl.validationMsg,
                open: pl.open,
                loginSuccessMsg: pl.loginSuccessMsg,
                auth: pl.auth,
                token: pl.token,
                admin_username: pl.admin_username,
                admin_name: pl.admin_name,
                tokenUpdate: pl.tokenUpdate,
                adminDP: pl.adminDP,
                admin_lname: pl.admin_lname,
                admin_phone: pl.admin_phone,
                admin_mobile: pl.admin_mobile,
            }
        }

        case ADMIN_AUTH_POST_FAIL: {

            return state = {
                validationMsg: pl.validationMsg,
                open: pl.open,
                loginSuccessMsg: pl.loginSuccessMsg,
                auth: pl.auth,
                token: pl.token,
                admin_username: pl.admin_username,
                admin_name: pl.admin_name,
                tokenUpdate: pl.tokenUpdate,
                adminDP:'',
                admin_lname: '',
                admin_phone: '',
                admin_mobile: '',
            }

        }

        case ADMIN_LOGOUT: {

            return state = {
                validationMsg: "",
                open: false,
                loginSuccessMsg: "",
                auth: false,
                token: "",
                admin_username: "",
                admin_name: "",
                tokenUpdate: false,
                adminDP:'',
                admin_lname: '',
                admin_phone: '',
                admin_mobile: '',
                admin_email: ''
            }
        }

        case ADMIN_CHANGE_DP: {

            return state = {
                ...state,
                adminDP: pl.adminDP,
            }
        }

        case ADMIN_GET_INFO: {
            return state = {
                ...state,
                admin_username: pl.admin_username,
                admin_name: pl.admin_name,
                admin_lname: pl.admin_lname,
                admin_phone: pl.admin_phone,
                admin_mobile: pl.admin_mobile,
                admin_email: pl.admin_email,
                admin_profile: "",
                admin_profile_class: "",
                admin_profile_status: "Loaded"
            }
        }

        case ADMIN_GET_INFO_END: {
            return state = {
                ...state,
                admin_profile_status: "Received"
            }
        }

        case ADMIN_GET_INFO_START: {
            return state = {
                ...state,
                admin_profile_status: "Loading ..."
            }
        }

        case ADMIN_SET_INFO: {
            return state = {
                ...state,
                admin_name: pl.admin_name,
                admin_lname: pl.admin_lname,
                admin_phone: pl.admin_phone,
                admin_mobile: pl.admin_mobile,
                admin_email: pl.admin_email,
                admin_profile: "Information Saved.",
                admin_profile_class: "success"
            }
        }

        case ADMIN_SET_INFO_START: {
            return state ={
                ...state,
                admin_profile: "Saving Information...",
                admin_profile_class: "info"
            }
        }

        case ADMIN_SET_INFO_END: {
            return state ={
                ...state,
                admin_profile: "",
                admin_profile_class: ""
            }
        }

        default: 
            return state;
    }
}